// Auth
import React from 'react';
import styles from './Input.module.sass';
import cn from 'classnames';

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  customLabel?: React.ReactNode;
  error?: string;
  className?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  showErrorText?: boolean;
}

const Input: React.FC<InputProps> = ({
  label,
  customLabel,
  className,
  wrapperClassName,
  labelClassName,
  error,
  startAdornment,
  endAdornment,
  showErrorText,
  ...inputProps
}) => {
  const showError = showErrorText && typeof error !== 'undefined';

  return (
    <div
      className={cn('field-wrapper', styles.field, wrapperClassName, {
        [styles.error]: typeof error !== 'undefined',
      })}
    >
      {label && (
        <label className={cn('field-label', labelClassName)}>{label}</label>
      )}
      {customLabel}
      <span className={cn('form-input', styles.inputAdornment, className)}>
        {startAdornment || endAdornment ? (
          <>
            {startAdornment}
            <input {...inputProps} />
            {endAdornment}
          </>
        ) : (
          <input {...inputProps} />
        )}
      </span>
      {showError && (
        <p
          className={cn(
            'text-size-small',
            'text-lineheight-short',
            styles.errorText
          )}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default Input;
